import React, { useRef, useState } from 'react';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import HeaderOne from '../../common/header/HeaderOne';
import FooterOne from '../../common/footer/FooterOne';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import withRouter from '../../utils/withRouterCustom';
import { bindActionCreators } from 'redux';
import * as appActions from '../../redux/actions/app-actions';
import { startSession } from '../../utils/auth';
import { notifyUser } from '../../utils/notification-service';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const ResetPassword = (props) => {


    const [loading, setLoading] = useState(false);
    const formRef = useRef();






    const onFinish = (values) => {




        setLoading(true);
        let args = {
            email: localStorage.getItem('emailForPassword'),
            password: values.newPassword,
            otp: values.otp
        }
        props.changePasswordViaOTP(args).then(async (resp) => {

            setLoading(false);
            console.log({ resp });
            if (resp && !resp.error) {

                notifyUser('Password Changed Successfully, Please Login Using New Password', 'success', 5);
                localStorage.removeItem('emailForPassword');
                props.router.navigate('/login');
            }

        }).catch((error) => {
            setLoading(false);
            notifyUser(error.message || 'Something went wrong', 'error', 5);
            console.log({ error });
        })

    };




    return (
        <>
            <Spin spinning={loading}>
                <HeaderOne />
                <div className='container my-5'>
                    <h1 className='text-center my-5'>Forgot Password</h1>
                    <div className='or-sky my-5' style={{ justifyContent: 'center', display: 'flex' }}>
                        {/* <div className="separator">OR</div> */}

                    </div>
                    <Form
                        ref={formRef}
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                            margin: 'auto'
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}

                        autoComplete="off"
                    >
                        <Form.Item
                            label="OTP"
                            name="otp"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter a valid OTP"
                                },
                                {
                                    message: "Please input a valid 6-digit OTP!",
                                    validator: (_, value) => {
                                        const regex = /^[0-9]{6}$/;
                                        if (!value || regex.test(value)) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject('Please input a valid 6-digit OTP!');
                                        }
                                    }

                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="New Password"
                            name="newPassword"
                            rules={[
                                {
                                    required: true,
                                    message: "Your password should be minimum eight characters and contain at least one uppercase letter, one lowercase letter, one special character and one number",
                                    pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?\/\\~-]).{8,}$/)
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Confirm Password"
                            name="confirmPassword"
                            rules={[
                                {
                                    required: true,
                                    message: "Confirm Password is required",

                                },
                                {
                                    message: "Passwords do not match!",

                                    validator: (_, value) => {

                                        if (formRef && formRef.current && formRef.current.getFieldValue('newPassword') !== value) {

                                            return Promise.reject('Passwords do not match!');
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }

                                }
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>



                        <Form.Item
                            className='centerContent'
                        >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>






                </div>
                <FooterOne />
            </Spin>
        </>)
}
function mapStateToProps(state) {
    return {

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        ResetPassword
    )
);
