export const startSession = (payload) => {

    localStorage.setItem('user', JSON.stringify(payload.user));
    localStorage.setItem('token', JSON.stringify(payload.token));

}

export const setUserStorage = (payload) => {

    localStorage.setItem('user', JSON.stringify(payload));

}


export const getStorageUser = (payload) => {

    return localStorage.getItem('user');

}


export const endSession = () => {

    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('previousURL');
}