import API from "../api/app-apis";
import { Types } from '../constants/user-types';


export function getAvailabilityDetails(id) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getAvailabilityDetails(id);
            return resp;
        } catch (err) {
            throw { message: err?.response?.data?.message };
        }
    };
}

export function postReview(payload) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.postReview(payload);
            return resp;
        } catch (err) {
            throw { message: err?.response?.data?.message };
        }
    };
}



export function cancelAppointment(id,status) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.cancelAppointment(id,status);
            return resp;
        } catch (err) {
            throw { message: err?.response?.data?.message };
        }
    };
}

export function getMyAppointments(id) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getMyAppointments(id);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}

export function getDocReviews(userId,payload) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getDocReviews(userId,payload);
            return resp;
        } catch (err) {
            throw { message: err?.response?.data?.message };
        }
    };
}

export function appointmentOTPGenerate(userId,payload) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.appointmentOTPGenerate(userId,payload);
            return resp;
        } catch (err) {
            throw { message: err?.response?.data?.message };
        }
    };
}

export function appointmentVerifyAndCreate(payload) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.appointmentVerifyAndCreate(payload);
            return resp;
        } catch (err) {
            throw { message: err?.response?.data?.message };
        }
    };
}
export function sendInvitation(payload) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.sendInvitation(payload);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}



export function setUserAction(payload) {
    return async function (dispatch, getState) {
        try {
            dispatch({ type: Types.SET_USER, payload: payload });
        } catch (e) {
            return { error: true };
        }
    };
}

export function getMyProfile(id) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getMyProfile(id);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}

export function updateMyProfile(id,data) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.updateMyProfile(id,data);
            return resp;
        } catch (err) {
            throw { message: err?.response?.data?.message };
        }
    };
}


export function getClinics(string) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getClinics(string);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}


export function getGaunpalikas(string) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getGaunpalikas(string);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}

export function getDoctorsClinicsByGaunPalika(id, string) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getDoctorsClinicsByGaunPalika(id, string);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}

export function getSpecialities(string) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getSpecialities(string);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}
export function getDoctorsBySpecialityId(specialityId, filters) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getDoctorsBySpecialityId(specialityId, filters);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}

export function getDocDetails(id) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getDocDetails(id);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}
export function getClinicDetails(id) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getClinicDetails(id);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}

export function getClinicDoctorsByState(id) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getClinicDoctorsByState(id);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}

export function getOtherInfo(id) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getOtherInfo(id);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}

export function getTestimonials(string) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getTestimonials(string);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}


export function loginViaGoogle(payload) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.loginViaGoogle(payload);
            return resp;
        } catch (err) {
            throw { message: err?.response?.data?.message };
        }
    };
}


export function signup(payload) {
    return async function (dispatch, getState) {
        try {

            let resp = await API.signup(payload);
            return resp;
        } catch (err) {
            console.log(err);

            throw { message: err?.response?.data?.message };
        }
    };
}

export function verifyOTP(payload) {
    return async function (dispatch, getState) {
        try {

            let resp = await API.verifyOTP(payload);
            return resp;
        } catch (err) {
            console.log(err);

            throw { message: err?.response?.data?.message };
        }
    };
}

export function resendOTP(payload) {
    return async function (dispatch, getState) {
        try {

            let resp = await API.resendOTP(payload);
            return resp;
        } catch (err) {
            console.log(err);

            throw { message: err?.response?.data?.message };
        }
    };
}

export function login(payload) {
    return async function (dispatch, getState) {
        try {

            let resp = await API.login(payload);
            return resp;
        } catch (err) {
            console.log(err);

            throw { message: err?.response?.data?.message };
        }
    };
}


export function getStatesOfNepal(string) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.getStatesOfNepal(string);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}


export function reviewsClinic(id) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.reviewsClinic(id);
            return resp;
        } catch (e) {
            return { error: true };
        }
    };
}


export function sendResetPasswordOTP(payload) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.sendResetPasswordOTP(payload);
            return resp;
        } catch (err) {
            throw { message: err?.response?.data?.message };
        }
    };
}


export function changePasswordViaOTP(args) {
    return async function (dispatch, getState) {
        try {
            let resp = await API.changePasswordViaOTP(args);
            return resp;
        } catch (err) {
            throw { message: err?.response?.data?.message };
        }
    };
}
