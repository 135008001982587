import { combineReducers } from "redux";
import languageReducer from "./language-reducer";
import userReducer from "./user-reducer";

const reducers = combineReducers({
  language: languageReducer,
  user: userReducer

});

export default reducers;
