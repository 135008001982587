import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { BsFillHospitalFill } from "react-icons/bs";
import { FaUserDoctor } from "react-icons/fa6";
import { FaRegCalendarCheck } from "react-icons/fa";
import withRouter from '../../utils/withRouterCustom';
import { connect } from 'react-redux';
import * as appActions from '../../redux/actions/app-actions';
import { bindActionCreators } from 'redux';
import config from '../../config';
class FeatureOne extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            specialities: [],
            clinics: [],
            otherInfo: {}
        }
    }

    componentDidMount() {
        const counters = document.querySelectorAll('.counter');

        counters.forEach(counter => {
            counter.Counter = 0;
            const targetValue = parseInt(counter.innerText);

            const animation = setInterval(function () {
                counter.Counter += targetValue / 400;

                if (counter.Counter >= targetValue) {
                    clearInterval(animation);
                    counter.Counter = targetValue;
                }

                counter.innerText = Math.ceil(counter.Counter);
            }, 10);
        });

        this.props.getStatesOfNepal().then((resp) => {

            if (resp && !resp.error) {
                this.setState({ specialities: resp });
            }
        }).catch((ex) => {
            console.log({ ex });
        })

        this.props.getClinics().then((resp) => {

            if (resp && !resp.error) {
                // if (resp.length == 1) {
                //     resp.push(resp[0]);
                //     resp.push(resp[0]);
                //     resp.push(resp[0]);
                //     resp.push(resp[0]);
                // }
                this.setState({ clinics: resp && resp.filter(x=>x.status) });
            }
        }).catch((ex) => {
            console.log({ ex });
        })

        this.props.getOtherInfo(1).then((resp) => {

            if (resp && !resp.error) {
                this.setState({ otherInfo: JSON.parse(resp.configData) });
            }
        }).catch((ex) => {
            console.log({ ex });
        })
    }
    render() {
        const { specialities, clinics, otherInfo } = this.state;
        const settings1 = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }
            ]
        };
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                }
            ]
        };
        return (
            <>
                <section className="features-one">
                    <div className="container">
                        {/* <div className="sec-title text-center">
                            <h2 className="sec-title__title">Best Wedding Vendors</h2>
                            <p className="sec-title__text">Excepteur s occaecat cupidatat proident sunt</p>
                        </div> */}
                        <div className="row">
                            <Slider {...settings1}>
                                {/* Start Features One Single */}
                                {specialities && specialities.map(item =>
                                    <div className="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                        <div style={{ cursor: 'pointer' }} onClick={() => {


                                            // this.props.router.navigate('/listings?selectedString=' + item.name + '&selectedStringId=' + item.id)
                                            this.props.router.navigate("listings?stateId=" + item.id)


                                        }} >
                                            <div className="features-one__single text-center zoom-in-effect">
                                                <div className="features-one__single-icon">
                                                    <img src={config.backendURL + item.image} width={'100px'} />
                                                </div>
                                                <div className="features-one__single-title">
                                                    <h2 className="zoom-in-effect" >{item.name}</h2>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* End Features One Single */}

                                {/* Start Features One Single
                                <div className="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">
                                    <div className="features-one__single text-center">
                                        <div className="features-one__single-icon">
                                            <span className="icon-employee"></span>
                                        </div>
                                        <div className="features-one__single-title">
                                            <h2><Link to={process.env.PUBLIC_URL + `/`}>Dentistry</Link></h2>
                                        </div>
                                    </div>
                                </div>
                                {/* End Features One Single */}

                            </Slider>
                        </div>
                    </div>
                </section>

                <section className='bg-secondary'>
                    <div className='container'>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 text-white">
                                <h1 className="text-white">{otherInfo.heading1}</h1>
                                <p>{otherInfo.description1}</p>
                                <div className='row my-4'>
                                    <div className='col-3'>
                                        <BsFillHospitalFill size={70} />
                                    </div>
                                    <div className='col-9'>
                                        <h5 className="text-white">{otherInfo.subheading1}</h5>
                                        <p>{otherInfo.subdescription1}</p>
                                    </div>
                                </div>

                                <div className='row my-4'>
                                    <div className='col-3'>
                                        <FaUserDoctor size={70} />
                                    </div>
                                    <div className='col-9'>
                                        <h5 className="text-white">{otherInfo.subheading2}</h5>
                                        <p>{otherInfo.subdescription2}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12" style={{ position: 'relative' }}>
                                <div className='sky-img1'>
                                    <img src={otherInfo.image1 && otherInfo.image1[0] && otherInfo.image1[0].url ? (config.APIURL1 + '/' + (otherInfo && otherInfo.image1 && otherInfo.image1[0] && otherInfo.image1[0].url)) : 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'} alt="img1" style={{
                                        // width: '451px',
                                        height: '260px',
                                        objectFit: 'cover'
                                    }} />
                                </div>
                                <div className='sky-img2'>
                                    <img src={otherInfo.image2 && otherInfo.image2[0] && otherInfo.image2[0].url ? (config.APIURL1 + '/' + (otherInfo && otherInfo.image2 && otherInfo.image2[0] && otherInfo.image2[0].url)) : 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'} alt="img2" style={{
                                        // width: '382px',
                                        height: '260px',
                                        objectFit: 'cover'
                                    }} />
                                </div>
                            </div>
                        </div>

                        <div className='row sky-counter text-white'>
                            {otherInfo.count1 && <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12'>
                                <span><b className="counter">{otherInfo.count1}</b><span className="plus">+</span></span>
                                <h4 className="text-white">{otherInfo.count1Title}</h4>
                            </div>
                            }

                            {otherInfo.count2 && <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12'>
                                <span><b className="counter">{otherInfo.count2}</b><span className="plus">+</span></span>
                                <h4 className="text-white">{otherInfo.count2Title}</h4>
                            </div>}

                            {otherInfo.count3 && <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12'>
                                <span><b className="counter">{otherInfo.count3}</b><span className="plus">+</span></span>
                                <h4 className="text-white">{otherInfo.count3Title}</h4>
                            </div>}

                            {otherInfo.count4 && <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12'>
                                <span><b className="counter">{otherInfo.count4}</b><span className="plus">+</span></span>
                                <h4 className="text-white">{otherInfo.count4Title}</h4>
                            </div>}

                        </div>
                    </div>
                </section>

                <section className='how-work'>
                    <div className='container' style={{
                        padding: '62px 40px',
                        backgroundColor: '#f5f5f5',
                        borderTopRightRadius: '30px',
                        borderTopLeftRadius: '30px'
                    }}>
                        <div className='row'>
                            <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12'>
                                <div className="section-title">
                                    <span className="subtitle">Work Process</span>
                                    <h1>{otherInfo.processTitle}</h1>
                                </div>
                            </div>
                            {/* <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                                <p>Donec rutrum congue leo eget malesuada. Nulla porttitor accumsan tincidunt. Vestibulum ante ipsum primis.</p>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12'>
                                <Link to='#' className="btn btn-info sky-btn">Make Appointment</Link>
                            </div> */}
                        </div>

                        <div className='row'>
                            <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                                <div className='row my-4'>
                                    <div className='col-3'>
                                        <BsFillHospitalFill size={40} />
                                    </div>
                                    <div className='col-9'>
                                        <h5>{otherInfo.step1Title}</h5>
                                        <p>{otherInfo.step1Description}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                                <div className='row my-4'>
                                    <div className='col-3'>
                                        <FaRegCalendarCheck size={40} />
                                    </div>
                                    <div className='col-9'>
                                        <h5>{otherInfo.step2Title}</h5>
                                        <p>{otherInfo.step2Description}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12'>
                                <div className='row my-4'>
                                    <div className='col-3'>
                                        <FaUserDoctor size={40} />
                                    </div>
                                    <div className='col-9'>
                                        <h5>{otherInfo.step3Title}</h5>
                                        <p>{otherInfo.step3Description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                <section className='sky-slider'>
                    <div className="sec-title text-center">
                        {/* <p className="sec-title__text">Top Rated Clinics</p> */}
                        <h2 className="sec-title__title">Top Rated Clinics</h2>
                    </div>
                    <div>
                        <Slider {...settings}>
                            {clinics && clinics.map(item =>
                                <div className="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                                    <div className='slideImage'>
                                        <Link to={'/listings-details-clinic/' + item.id}>
                                            <img src={config.backendURL + item.image?.url} width={'300px'} />
                                        </Link>
                                    </div>
                                    <div className='slideData m-4'>
                                        <h5 className='my-2'><Link to={'/listings-details-clinic/' + item.id}>{item?.firstName} {item?.lastName}</Link></h5>
                                        <div className='sky-slide-content' style={{ display: 'flex', flexWrap: 'wrap' }}>{item.specialities.map((x, idx) => <><span>{x.name}</span><span style={{ display: idx >= item.specialities.length - 1 ? 'none' : '' }}>,</span></>)}</div>
                                        <p>{item?.districtDetail?.district_name}</p>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                </section>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        FeatureOne
    )
);

