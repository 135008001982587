import React, { useState } from 'react';
import HeaderTwo from '../common/header/HeaderTwo';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import ListingDetailsClinic from '../components/listing/ListingDetailsClinic';

const ListingsDetailsClinic = () => {
    const [details,setDetails]=useState({});
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading={details?.firstName ?details?.firstName : "" + ' '+ details?.lastName ? details.lastName : ""}
                currentPage={details?.firstName ?details?.firstName : "" + ' '+ details?.lastName ? details.lastName : "" + " Details"}
            />
            <ListingDetailsClinic 
            setDetails={setDetails}
            />
            <FooterOne />
        </>
    )
}

export default ListingsDetailsClinic;