import React from 'react'
import HeaderOne from '../../common/header/HeaderOne'
import Breadcrumb from '../../common/breadcrumb/Breadcrumb'
import FooterOne from '../../common/footer/FooterOne'
import { TransactionsList } from './TransactionsList'

export const Transactions = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="My Transactions"
                currentPage="My Transactions" 
            />
            <TransactionsList />
            <FooterOne />
        </>
    )
}
