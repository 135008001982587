import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import HeaderOne from '../../common/header/HeaderOne';
import FooterOne from '../../common/footer/FooterOne';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import withRouter from '../../utils/withRouterCustom';
import { bindActionCreators } from 'redux';
import * as appActions from '../../redux/actions/app-actions';
import { startSession } from '../../utils/auth';
import { notifyUser } from '../../utils/notification-service';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const EnterEmail = (props) => {


    const [loading, setLoading] = useState(false);

    const { router } = props;
        console.log({ router })
        let params = router?.location?.search;
        let loggedIn = new URLSearchParams(params).get("logged"); 





    const onFinish = (values) => {




        setLoading(true);
        props.sendResetPasswordOTP({ email: values.email }).then(async (resp) => {

            setLoading(false);
            console.log({ resp });
            if (resp && !resp.error) {


                notifyUser('OTP Sent', 'success', 5);
                localStorage.setItem('emailForPassword', values.email);
                props.router.navigate('/reset-password');
            }
            else {
                setLoading(false);
                notifyUser(resp?.error?.message || 'Something went wrong', 'error', 5);
            }

        }).catch((error) => {
            setLoading(false);
            notifyUser(error.message || 'Something went wrong', 'error', 5);
            console.log({ error });
        })

    };


    const email = localStorage.getItem('emailForPassword');

   
    return (
        <>
            <Spin spinning={loading}>
                <HeaderOne />
                <div className='container my-5'>
                    <h1 className='text-center my-5'>Forgot Password</h1>
                    <div className='or-sky my-5' style={{ justifyContent: 'center', display: 'flex' }}>
                        {/* <div className="separator">OR</div> */}
                        <span>Enter your Email to receive an OTP</span>
                    </div>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                            margin: 'auto'
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}

                        autoComplete="off"
                    
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter a valid Email",
                                    type: 'email'
                                },
                            ]}
                            initialValue={email ? email : undefined}
                        >
                            <Input disabled={loggedIn}/>
                        </Form.Item>



                        <Form.Item
                            className='centerContent'
                        >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>






                </div>
                <FooterOne />
            </Spin>
        </>)
}
function mapStateToProps(state) {
    return {

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        EnterEmail
    )
);
