import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import WOW from 'wowjs';
import { FaAngleUp } from "react-icons/fa";

//Pages
import HomeDefault from "./pages/HomeDefault";
import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import About from "./pages/About";
import ListingsOne from "./pages/ListingsOne";
import ListingsTwo from "./pages/ListingsTwo";
import ListingsThree from "./pages/ListingsThree";
import ListingsDetails from "./pages/ListingsDetails";
import Categories from "./pages/Categories";
import Visited from "./pages/Visited";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import ScrollToTopRoute from './components/scroll-to-top-route/ScrollToTopRoute';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';
import { MyAccount } from './components/account/MyAccount';
import Login from './components/login/Login';
import ListingsDetailsClinic from './pages/ListingDetailsClinic';
import Signup from './components/login/Signup';
import { MyAppointments } from './components/appointment/MyAppointments';
import { Transactions } from './components/transactions/Transactions';
import Thankyou  from './pages/Thankyou';
import EnterEmail from './components/login/EnterEmail';
import ResetPassword from './components/login/ResetPassword';

function App() {
  useEffect(() => {
    new WOW.WOW({
        live: false
      }).init();
    }, []
  );
  const { createMemoryHistory } = require("history");
  const history = createMemoryHistory();
  return (
    <Router location={history.location} navigator={history}>
    <ScrollToTopRoute />
        <Routes>
            <Route path={`${process.env.PUBLIC_URL}/`} exact element={<HomeDefault />} />
            {/* <Route path={`${process.env.PUBLIC_URL}/home-two`} exact element={<HomeTwo />} /> */}
            {/* <Route path={`${process.env.PUBLIC_URL}/home-three`} exact element={<HomeThree />} /> */}
            <Route path={`${process.env.PUBLIC_URL}/about`} exact element={<About />} />
            <Route path={`${process.env.PUBLIC_URL}/listings`} exact element={<ListingsOne />} />
            {/* <Route path={`${process.env.PUBLIC_URL}/listings2`} exact element={<ListingsTwo />} /> */}
            {/* <Route path={`${process.env.PUBLIC_URL}/listings3`} exact element={<ListingsThree />} /> */}
            <Route path={`${process.env.PUBLIC_URL}/listings-details-doc/:id`} exact element={<ListingsDetails />} />
            <Route path={`${process.env.PUBLIC_URL}/listings-details-clinic/:id`} exact element={<ListingsDetailsClinic />} />
            
            {/* <Route path={`${process.env.PUBLIC_URL}/categories`} exact element={<Categories />} /> */}
            {/* <Route path={`${process.env.PUBLIC_URL}/visited`} exact element={<Visited />} /> */}
            {/* <Route path={`${process.env.PUBLIC_URL}/team`} exact element={<Team />} /> */}
            {/* <Route path={`${process.env.PUBLIC_URL}/team-details`} exact element={<TeamDetails />} /> */}
            {/* <Route path={`${process.env.PUBLIC_URL}/blog`} exact element={<Blog />} /> */}
            <Route path={`${process.env.PUBLIC_URL}/account`} exact element={<MyAccount />} />
            <Route path={`${process.env.PUBLIC_URL}/appointments`} exact element={<MyAppointments />} />
            <Route path={`${process.env.PUBLIC_URL}/transactions`} exact element={<Transactions />} />
            <Route path={`${process.env.PUBLIC_URL}/thank-you`} exact element={<Thankyou />} />
            <Route path={`${process.env.PUBLIC_URL}/login`} exact element={<Login />} />
            <Route path={`${process.env.PUBLIC_URL}/enter-email`} exact element={<EnterEmail />} />
            <Route path={`${process.env.PUBLIC_URL}/reset-password`} exact element={<ResetPassword />} />
            <Route path={`${process.env.PUBLIC_URL}/signup`} exact element={<Signup />} />
            {/* <Route path={`${process.env.PUBLIC_URL}/blog-details`} exact element={<BlogDetails />} /> */}
            <Route path={`${process.env.PUBLIC_URL}/contact`} exact element={<Contact />} />
            <Route path={`${process.env.PUBLIC_URL}/*`} exact element={<Error />} />
        </Routes>
        <ScrollToTop className="scrollUp" smooth top="1500" component={<FaAngleUp />} />
    </Router>
  );
}

export default App;
