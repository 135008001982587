import React, { useEffect, useState } from 'react';
import * as appActions from '../../redux/actions/app-actions';
import {
    Button,
    Cascader,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Spin,
    Switch,
    TreeSelect,
} from 'antd';
import { connect } from 'react-redux';
import withRouter from '../../utils/withRouterCustom';
import { bindActionCreators } from 'redux';
import { notifyUser } from '../../utils/notification-service';
import { getStorageUser, setUserStorage, startSession } from '../../utils/auth';
import moment from 'moment';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
const ProfileForm = (props) => {
    const [loading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false);
    const [formData, setFormData] = useState(null);

    let user = getStorageUser();
    if (user) {
        var _user = JSON.parse(user);
    }
    useEffect(() => {



        profileInitializer();
    }, [])

    useEffect(() => {
        profileInitializer();
    }, [props]);


    const profileInitializer = () => {

        if (user) {
            props.getMyProfile(_user?.id).then((resp) => {
                setLoading(false);

                if (resp && !resp.error) {
                    if (resp.dob) { resp.dob = dayjs(resp.dob); }
                    setFormData(resp);


                }
            }).catch((error) => {
                setLoading(false);
                notifyUser(error.message || 'Something went wrong', 'error', 5);
                console.log({ error });
            })
        }

    }

    const onFinish = (values) => {


        setFormLoading(true);
        values.dob = dayjs(values.dob).format('YYYY/MM/DD');
        console.log({ values });


        props.updateMyProfile(_user?.id, values).then((resp) => {


            if (resp && !resp.error) {
                notifyUser('Profile Updated', 'success', 3);
                setUserStorage(resp);
                props.setUserAction(resp);
                setTimeout(() => {
                    setFormLoading(false);
                    props.router.navigate('/account');
                }, 1000);


            }
            else {
                setFormLoading(false);
            }
        }).catch((error) => {
            setFormLoading(false);
            notifyUser(error.message || 'Something went wrong', 'error', 5);
            console.log({ error });
        })
    }
    let isGoogleProfile = formData?.user_account_type === 'google';
    return (
        <div className='container my-5'>
            <Spin spinning={formLoading}>
                {!loading &&
                    <Form
                        name='form-profile'
                        labelCol={{
                            span: 24,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: 800,
                            margin: 'auto'
                        }}
                        layout="horizontal"
                        initialValues={formData}
                        onFinish={onFinish}
                    >
                        <Row>
                            <Col span={12}>
                                <Form.Item name={'firstName'} label="First Name" rules={[{ required: true, message: 'First Name is required' }]} style={{ width: "99%", marginRight: '1%' }}>
                                    <Input disabled={isGoogleProfile} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name={'lastName'} label="Last Name" rules={[{ required: true, message: 'Last Name is required' }]} style={{ width: "99%", marginLeft: '1%' }}>
                                    <Input disabled={isGoogleProfile} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name={'email'} label="Email" rules={[{ required: true, message: 'Email is required', type: 'email' }]}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name={'phone'} label="Mobile" rules={[{ required: true, message: 'Mobile is required' }]} style={{ width: "99%", marginLeft: '1%' }}>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name={'dob'} label="Date of Birth" rules={[{ required: true, message: 'DOB is required' }]} style={{ width: "99%", marginLeft: '1%' }}>
                                    <DatePicker format={'YYYY/MM/DD'} />
                                </Form.Item>
                            </Col>


                        </Row>
                        <Row>
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                        </Row>
                    </Form>

                }
            </Spin>
            <div style={{ float: 'right' }}>
                <span style={{textDecoration:'underline',cursor:'pointer'}} onClick={() => {
                    localStorage.setItem('emailForPassword', formData?.email);
                    props.router.navigate('/enter-email?logged=true');
                }}>Change Password?</span>
            </div>
        </div>
    );
};
function mapStateToProps(state) {
    return {
        ...state.user
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        ProfileForm
    )
);
