import moment from 'moment'
import React from 'react'

function RenderReview(props) {

    let review = props.review;
    function StarList({ count }) {
        const stars = Array.from({ length: count }, (_, index) => (
            <li key={index}><span className="icon-star"></span></li>
        ));

        return (
            <>
                {stars}
            </>
        );
    }

    return (

        <div className="comment-one__single">

            <div className="comment-one__content" style={{ width: '100%' }}>
                <h3>{review.fullName}</h3>
                <span>{moment(review.createdAt).format("MMM Do YY")}</span>
                <p>{review.comment}
                </p>
                <ul className="reviews-box" style={{ display: 'flex' }}>

                    <StarList count={review.rating} /> {/* Render 5 stars */}
                </ul>
                {review?.doctor ? <span>Dr. {review?.doctor?.firstName} {review?.doctor?.lastName}</span> : ''}
            </div>
        </div>
    )
}

export default RenderReview