import React from 'react'
import HeaderOne from '../../common/header/HeaderOne'
import Breadcrumb from '../../common/breadcrumb/Breadcrumb'
import FooterOne from '../../common/footer/FooterOne'
import AppointmentList from './AppointmentList'

export const MyAppointments = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="My Appointments"
                currentPage="My Appointments" 
            />
            <AppointmentList />
            <FooterOne />
        </>
      )
}
