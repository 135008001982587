import React from 'react'
import HeaderOne from '../../common/header/HeaderOne'
import Breadcrumb from '../../common/breadcrumb/Breadcrumb'
import FooterOne from '../../common/footer/FooterOne'
import ProfileForm from './ProfileForm'

export const MyAccount = () => {
  return (
    <>
        <HeaderOne />
        <Breadcrumb 
            heading="My Account"
            currentPage="My Account" 
        />
        <ProfileForm />
        <FooterOne />
    </>
  )
}
