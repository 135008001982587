import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { notifyUser } from '../../utils/notification-service';

function PostReview(props) {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        setFormData({ ...formData, name: props.isModalOpen?.firstName + ' ' + props.isModalOpen?.lastName })

    }, [props.isModalOpen])
    console.log({ formData });
    console.log(props.isModalOpen);


    const onFinish = () => {
        const { isModalOpen } = props;
        if (!formData.comment || formData.comment == "") {
            notifyUser('Please enter a comment', 'warning');
            return;
        }
        if (!formData.rating || formData.rating == "") {
            notifyUser('Please enter a rating', 'warning');
            return;
        }

        let args = {
            rating: Number(formData.rating),
            client_id: isModalOpen.client_id,
            doctor_id: isModalOpen.doctor_id,
            clinic_id: isModalOpen.clinic_id,
            appointment_id: isModalOpen.id,
            comment: formData.comment,
            fullName: formData.name

        }
        setLoading(true);
        props.postReviewFunction(args).then((resp) => {
            setLoading(false);
            if (resp && resp.id) {
                notifyUser("Review Posted", 'success', 7);
                props.handleReviewModal(null);
                props.initializer();
            }
            else {
                notifyUser(resp.message || "Something went wrong!", 'error', 7);
            }

        }).catch((err) => {
            notifyUser(err.message || "Something went wrong!", 'error', 7);
            setLoading(false);
        })
    }


    return (
        <Modal width={1000} open={props.isModalOpen} onCancel={() => props.handleReviewModal(null)} footer={
            null
        }>
            <div className="comment-form listings-details-page__content-form">
                <h3 className="comment-form__title">Add Story</h3>
            
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="comment-form__input-box">
                                <input required disabled type="text" placeholder="Full name" name="name" onChange={(e) => setFormData({ ...formData, name: e.target.value })} value={formData?.name} />
                            </div>
                        </div>
                        {/* <div className="col-xl-6">
                            <div className="comment-form__input-box">
                                <input type="email" placeholder="Email Address" name="email" />
                            </div>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="comment-form__input-box text-message-box">
                                <textarea name="message" placeholder="Comments" required
                                    onChange={(e) => setFormData({ ...formData, comment: e.target.value })} value={formData?.comment}></textarea>
                            </div>
                            <div className="row">
                                <div className="col-xl-2">
                                    <label>Rating</label>
                                </div>
                                <div className="col-xl-6">
                                    <div class="rate">
                                        <input type="radio" id="star5" name="rate" value="5" onChange={(e) => setFormData({ ...formData, rating: e.target.value })} />
                                        <label for="star5" title="text">5 stars</label>
                                        <input type="radio" id="star4" name="rate" value="4" onChange={(e) => setFormData({ ...formData, rating: e.target.value })} />
                                        <label for="star4" title="text">4 stars</label>
                                        <input type="radio" id="star3" name="rate" value="3" onChange={(e) => setFormData({ ...formData, rating: e.target.value })} />
                                        <label for="star3" title="text">3 stars</label>
                                        <input type="radio" id="star2" name="rate" value="2" onChange={(e) => setFormData({ ...formData, rating: e.target.value })} />
                                        <label for="star2" title="text">2 stars</label>
                                        <input type="radio" id="star1" name="rate" value="1" onChange={(e) => setFormData({ ...formData, rating: e.target.value })} />
                                        <label for="star1" title="text">1 star</label>
                                    </div>
                                </div>

                            </div>

                            <div className="row mt-3">
                                <div className="comment-form__btn-box">
                                    <button  className="comment-form__btn thm-btn" onClick={() => onFinish()}>Post Story</button>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>
        </Modal>
    )
}

export default PostReview