import React from 'react'
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';
import { Button, Card } from 'antd';
import withRouter from '../utils/withRouterCustom';

const Thankyou = (props) => {

    console.log(props.router);
    let params = props?.router?.location?.search;
    let navigate = props?.router?.navigate;
    let id = new URLSearchParams(params).get("id");
    return (
        <>
            <HeaderOne />
            {/* <Breadcrumb 
                heading={'Thank You'}
                currentPage={'Thank You'}
            /> */}
            <div className='container' style={{ margin: '10% auto', textAlign: 'center',display:'flex',flexDirection:'column',alignItems:'center' }}>
                <h1>Thank You for booking an appointment</h1>
                <Button className='sky-btn m-3' style={{
                    padding: '20px 20px',
                    display: 'flex',
                    alignItems: 'center'
                }} onClick={() => navigate('/appointments?id=' + id)}>See Details</Button>
            </div>

            <FooterOne />
        </>
    )
}

export default withRouter(Thankyou);
