import  {Types}  from '../constants/language-types';

const initialState = {
    language: null
};
export default function languageReducer(state = initialState, action) {
    switch (action.type) {
        case Types.CHANGE_LANGUAGE:
            return {...state, language: action.payload}
        default:
            return state;
    }
}