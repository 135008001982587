// axiosInstance.js
import axios from 'axios';
import config from '../config';

const instance = axios.create({
    baseURL: config.APIURL1, // Replace with your API base URL
    timeout: 5000, // Set your desired timeout
    headers: {
        'Content-Type': 'application/json',
        // Add any other default headers you need
    },
});

// Add request interceptor
instance.interceptors.request.use(
    (config) => {
        // You can modify the request config here, e.g., add authentication headers
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add response interceptor
instance.interceptors.response.use(
    (response) => {
        // You can handle successful responses here
        return response.data;
    },
    (error) => {
        // You can handle errors here, e.g., redirect to login for unauthorized requests
        return Promise.reject(error);
    }
);

export default instance;