import React, { useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';

export const Step2Form = (props) => {
  const { otp, setOtp,resendOTP } = props;
  return (
    <>
      <div className='signup-otp text-center'>
        <h3 className='my-5'>Enter One Time Password</h3>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
        <br />
        <div style={{margin:'10px 10px'}}>
          <Button type="primary" onClick={props.onSuccess}>Submit</Button>
        </div>
        <span style={{textDecoration:'underline',cursor:'pointer'}} onClick={props.resendOTP} to={process.env.PUBLIC_URL + `/`}>If not received the OTP Click Here?</span >

      </div>
    </>
  )
}
